html {
  height: 100%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  color: #333e5a;
  min-height: 100%;
}

header.act {
  background: #f0293e;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 2em;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

form.join {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

form.join > div {
  width: 100%;
  margin-bottom: 1em;
}
form.join > div > label {
  display: block;
  margin-bottom: 0.3em;
}
form.join > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #333e5a;
}

.join button {
  background: #333e5a;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}
.join button:hover {
  filter: brightness(150%);
}

/* .room {
  position: relative;
} */

.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.local-participant {
  text-align: center;
  margin-bottom: 2em;
}
.remote-participants {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 2em 2em;
}
.participant {
  background: #333e5a;
  padding: 10px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
}
.participant:last-child {
  margin-right: 0;
}
.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}

video#videoElement {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
}

footer {
  background: #333e5a;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  padding: 1em 0;
}

footer a {
  color: #fff;
}

main {
  flex-grow: 1;
}

main #join-flow button:hover {
  background-color: #2681f2;
}
